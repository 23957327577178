@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Livvic:wght@100;200;300;400;500;600;700;900&display=swap");

@font-face {
    font-family: "Basis Grotesque Arabic Pro";
    src: url(../fonts/BasisGrotesqueArabicPro-Regular.woff);
}
@font-face {
    font-family: "Basis Grotesque Arabic Pro Bold";
    src: url(../fonts/BasisGrotesqueArabicPro-Bold.woff);
}
@font-face {
    font-family: "Recoleta";
    src: url(../fonts/recoleta-regulardemo.otf);
}
$theme-colors: (
    "primary": #fb862b,
    "dark": #000000,
    "dark-1": #160a3d,
    "secondary": #ffffff,
    "coloured": #00b67a,
);

.pointer {
    cursor: pointer;
}
Input:focus {
    box-shadow: none !important;
    outline: none;
}
.defaultFont {
    font-family: "poppins";
}
$font-weights: 100 200 300 400 500 600 700 800 900;
$font-families: "Barlow" "Livvic";
@import "node_modules/bootstrap/scss/bootstrap";
.rightColumn {
    margin-left: -30px;
    @media (max-width: 991px) {
        margin-left: 0px !important;
    }
}
.home-main {
    position: relative;
    &__desktop-header {
        @media (max-width: 650px) {
            display: none;
        }
    }
    &__mobile-top-header {
        display: none;
        @media (max-width: 650px) {
            display: block;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 500;
        }
    }
    &__mobile-bottom-header {
        display: none;
        @media (max-width: 650px) {
            display: block;
            position: fixed;
            bottom: 0;
            z-index: 500;
            width: 100%;
        }
    }
    &__header {
        background-color: #2d3232;
        min-height: 58px;
        border: 1px solid #2d3232;
        &__text {
            font-size: 13px;
            letter-spacing: 0px;
            // line-height: 20px;
            font-weight: 300;
            font-family: "Basis Grotesque Arabic Pro";
            @media (max-width: 650px) {
                text-align: left !important;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                i {
                    margin-right: 7px;
                }
            }
        }
    }
    &__logo {
        background-color: #000000;
        min-height: 100px;
        border: 1px solid #000000;
        &__outer {
            max-width: 65%;
            margin: auto;
            @media (max-width: 650px) {
                max-width: 100% !important;
                .logo {
                    height: 50px !important;
                }
            }
            @media (max-width: 760px) and (min-width: 651px) {
                max-width: 90%;
            }
            @media (max-width: 1285px) and (min-width: 1234px) {
                max-width: 73%;
            }
            @media (max-width: 1233px) and (min-width: 1186px) {
                max-width: 77%;
            }
            @media (max-width: 1185px) and (min-width: 1086px) {
                max-width: 80%;
            }
            @media (max-width: 1087px) and (min-width: 1011px) {
                max-width: 83%;
            }
            @media (max-width: 1010px) and (min-width: 992px) {
                max-width: 86%;
            }
            &__text {
                font-size: 25px;
                font-family: "Recoleta";
                line-height: 30px;
                @media (max-width: 650px) {
                    font-size: 20px;
                }
            }
        }
    }
    &__campaign {
        background: url("../../assets/img/bgImage.png");
        background-repeat: no-repeat;
        background-size: cover;
        &__left {
            background-color: #000000;
            &__top {
                position: relative;
                @media (max-width: 767.98px) {
                    width: 80%;
                }

                @media (max-width: 601.98px) {
                    width: 90%;
                }
                @media (max-width: 449.98px) {
                    width: 100%;
                }
                &__text {
                    font-size: 35px;
                    letter-spacing: 0px;
                    line-height: 39px;
                    font-weight: 800;
                    font-family: "Basis Grotesque Arabic Pro";
                    @media (max-width: 320px) {
                        font-size: 22px;
                    }
                    @media (max-width: 400px) and (min-width: 321px) {
                        font-size: 26px;
                    }
                    @media (max-width: 435px) and (min-width: 401px) {
                        font-size: 28px;
                    }
                    @media (max-width: 500px) and (min-width: 436px) {
                        font-size: 30px;
                    }
                    @media (max-width: 600px) and (min-width: 501px) {
                        font-size: 40px;
                    }
                    @media (max-width: 877px) and (min-width: 858px) {
                        font-size: 36px;
                    }
                    @media (max-width: 916px) and (min-width: 878px) {
                        font-size: 39px;
                    }
                    @media (max-width: 955px) and (min-width: 917px) {
                        font-size: 40px;
                    }
                    @media (max-width: 991px) and (min-width: 956px) {
                        font-size: 42px;
                    }
                }
                &__img1 {
                    position: absolute;
                    left: 160px;
                    bottom: 5px;
                    @media (max-width: 320px) {
                        left: 75px;
                        bottom: 7px;
                    }
                    @media (max-width: 424px) and (min-width: 321px) {
                        left: 116px;
                        bottom: 5px;
                    }
                    @media (max-width: 650px) {
                        left: auto;
                        right: -10px;
                        bottom: 0px;
                        transform: rotate(90deg);
                    }
                    // @media (max-width: 1152px) and (min-width: 1024px) {
                    //     left: 95px;
                    //     bottom: 6px;
                    // }
                }
            }
            &__img2 {
                margin-top: -35px;
                // @media (max-width: 320px) {
                //     margin-left: 10px;
                //     margin-top: -28px;
                // }
                // @media (max-width: 424px) and (min-width: 321px) {
                //     margin-top: -30px;
                //     margin-left: 10px;
                // }
                @media (max-width: 767.98px) {
                    width: 80%;
                }
                @media (max-width: 650px) {
                    margin-top: -30px;
                    margin-left: 10px;
                }

                @media (max-width: 499.98px) {
                    width: 90%;
                    margin-top: 0;
                }
                // @media (max-width: 1284px) and (min-width: 992px) {
                //     margin-top: 0px !important;
                // }
            }
            &__steps {
                font-size: 20px;
                font-family: "Recoleta";
            }
        }
        &__right {
            border-radius: 4px;
            margin-top: -20px;
            margin-left: -50px;
            @media (max-width: 991px) {
                margin-top: 5px;
                margin-left: 0px;
            }
            // @media (max-width: 1284px) and (min-width: 1024px) {
            //     margin-top: -16px;
            // }
            &__text {
                font-size: 23px;
                line-height: 30px;
                font-weight: 800;
                font-family: "Basis Grotesque Arabic Pro Bold";
                // text-align: center;
            }
            &__form {
                max-width: 85%;
                margin: auto;
                &__label {
                    font-size: 16px;
                    font-family: "Basis Grotesque Arabic Pro";
                    text-align: center;
                }
                &__fields {
                    border-radius: 2px;
                    background-color: #fafafa;
                    border: 1px solid #ececec;
                    &::placeholder {
                        font-size: 12px;
                        letter-spacing: 0px;
                        line-height: 58px;
                        color: #999999;
                        font-weight: 400;
                        font-family: "Basis Grotesque Arabic Pro";
                    }
                    &__date {
                        height: 50px;
                    }
                }
                &__btnStyling {
                    font-size: 24px;
                    line-height: 35px;
                    font-weight: 800;
                    font-family: "Basis Grotesque Arabic Pro";
                }
            }
            &__bottom {
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 16px;
                color: #4a4a4a;
                font-weight: 300;
                font-family: "Basis Grotesque Arabic Pro";
            }
        }
        &__footer__text {
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 15px;
            color: #ffffff;
            font-weight: 500;
            font-family: "Basis Grotesque Arabic Pro";
        }
        &__footer__left__text {
            font-size: 12px;
            letter-spacing: 0px;
            line-height: 20px;
            color: #000000;
            font-weight: 300;
            font-family: "Basis Grotesque Arabic Pro";
        }
        &__footer__right__text {
            font-size: 12px;
            letter-spacing: 0px;
            line-height: 20px;
            text-decoration: underline;
            color: #000000;
            font-weight: 300;
            font-family: "Basis Grotesque Arabic Pro";
        }
    }
}

//******* Vialuxury--V2 *******//
.home-vialuxury {
    position: relative;
    &__desktop-header {
        @media (max-width: 650px) {
            display: none;
        }
    }
    &__mobile-top-header {
        display: none;
        @media (max-width: 650px) {
            display: block;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 500;
        }
    }
    &__mobile-bottom-header {
        display: none;
        @media (max-width: 650px) {
            display: block;
            position: fixed;
            bottom: 0;
            z-index: 500;
            width: 100%;
        }
    }
    &__header {
        background-color: #2d3232;
        min-height: 58px;
        border: 1px solid #2d3232;
        &__text {
            font-size: 13px;
            letter-spacing: 0px;
            // line-height: 20px;
            font-weight: 300;
            font-family: "Basis Grotesque Arabic Pro";
            @media (max-width: 650px) {
                text-align: left !important;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                i {
                    margin-right: 7px;
                }
            }
        }
    }
    &__logo {
        background-color: #35c890;
        min-height: 100px;
        border: 1px solid #35c890;
        &__outer {
            max-width: 65%;
            margin: auto;
            &--row {
                @media (min-width: 1024px) {
                    width: 878px;
                }
            }
            @media (max-width: 650px) {
                max-width: 100% !important;
                .logo {
                    height: 50px !important;
                }
            }
            @media (max-width: 760px) and (min-width: 651px) {
                max-width: 90%;
            }
            @media (max-width: 1285px) and (min-width: 1234px) {
                max-width: 73%;
            }
            @media (max-width: 1233px) and (min-width: 1186px) {
                max-width: 77%;
            }
            @media (max-width: 1185px) and (min-width: 1086px) {
                max-width: 80%;
            }
            @media (max-width: 1087px) and (min-width: 1011px) {
                max-width: 83%;
            }
            @media (max-width: 1010px) and (min-width: 992px) {
                max-width: 86%;
            }
            &__text {
                font-size: 25px;
                font-family: "Recoleta";
                line-height: 30px;
                @media (max-width: 650px) {
                    font-size: 20px;
                }
            }
        }
    }
    &__campaign {
        background: url("../img/bgImageV2.png");
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        position: relative;
        z-index: 1;
        .clipper {
            clip-path: polygon(
                52% 84%,
                100% 77%,
                100% 100%,
                100% 100%,
                0 100%,
                0 92%
            );
            background: #f893ad;
            width: 100%;
            height: 1150px;
            position: absolute;
            bottom: 0;
            z-index: -1;
        }

        &__left {
            background-color: #000000;
            &__top {
                position: relative;
                @media (max-width: 767.98px) {
                    width: 80%;
                }

                @media (max-width: 601.98px) {
                    width: 90%;
                }
                @media (max-width: 449.98px) {
                    width: 100%;
                }
                &__text {
                    font-size: 27px;
                    letter-spacing: 0px;
                    line-height: 39px;
                    font-weight: 800;
                    font-family: "Basis Grotesque Arabic Pro";
                    @media (max-width: 320px) {
                        font-size: 22px;
                    }
                    @media (max-width: 400px) and (min-width: 321px) {
                        font-size: 26px;
                    }
                    @media (max-width: 435px) and (min-width: 401px) {
                        font-size: 28px;
                    }
                    @media (max-width: 500px) and (min-width: 436px) {
                        font-size: 30px;
                    }
                    @media (max-width: 600px) and (min-width: 501px) {
                        font-size: 40px;
                    }
                    @media (max-width: 877px) and (min-width: 858px) {
                        font-size: 36px;
                    }
                    @media (max-width: 916px) and (min-width: 878px) {
                        font-size: 39px;
                    }
                    @media (max-width: 955px) and (min-width: 917px) {
                        font-size: 40px;
                    }
                    @media (max-width: 991px) and (min-width: 956px) {
                        font-size: 42px;
                    }
                }
                &__img1 {
                    position: absolute;
                    right: -27px;
                    bottom: 49px;
                    transform: rotate(62deg);
                    @media (max-width: 320px) {
                        left: 75px;
                        bottom: 7px;
                    }
                    @media (max-width: 424px) and (min-width: 321px) {
                        left: 116px;
                        bottom: 5px;
                    }
                    @media (max-width: 650px) {
                        left: auto;
                        right: -10px;
                        bottom: 0px;
                        transform: rotate(90deg);
                    }
                    // @media (max-width: 1152px) and (min-width: 1024px) {
                    //     left: 95px;
                    //     bottom: 6px;
                    // }
                }
            }
            &__img2 {
                margin-top: -35px;
                // @media (max-width: 320px) {
                //     margin-left: 10px;
                //     margin-top: -28px;
                // }
                // @media (max-width: 424px) and (min-width: 321px) {
                //     margin-top: -30px;
                //     margin-left: 10px;
                // }
                @media (max-width: 767.98px) {
                    width: 80%;
                }
                @media (max-width: 650px) {
                    margin-top: -30px;
                    margin-left: 10px;
                }

                @media (max-width: 499.98px) {
                    width: 90%;
                    margin-top: 0;
                }
                // @media (max-width: 1284px) and (min-width: 992px) {
                //     margin-top: 0px !important;
                // }
            }
            &__steps {
                font-size: 20px;
                font-family: "Recoleta";
            }
        }
        &__right {
            border-radius: 4px;
            // margin-top: -20px;
            margin-left: -50px;
            @media (max-width: 991px) {
                margin-top: 5px;
                margin-left: 0px;
            }
            // @media (max-width: 1284px) and (min-width: 1024px) {
            //     margin-top: -16px;
            // }
            &__text {
                font-size: 23px;
                line-height: 30px;
                font-weight: 800;
                font-family: "Basis Grotesque Arabic Pro Bold";
                // text-align: center;
                span {
                    color: #fb862b;
                }
            }
            &__form {
                max-width: 81%;
                margin: auto;
                &__label {
                    font-size: 16px;
                    font-family: "Basis Grotesque Arabic Pro";
                    text-align: center;
                }
                &__fields {
                    border-radius: 2px;
                    background-color: #fafafa;
                    border: 1px solid #ececec;
                    &::placeholder {
                        font-size: 12px;
                        letter-spacing: 0px;
                        line-height: 58px;
                        color: #999999;
                        font-weight: 400;
                        font-family: "Basis Grotesque Arabic Pro";
                    }
                    &__date {
                        height: 50px;
                    }
                }
                &__btnStyling {
                    font-size: 24px;
                    line-height: 35px;
                    font-weight: 800;
                    font-family: "Basis Grotesque Arabic Pro";
                }
            }
            &__bottom {
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 16px;
                color: #4a4a4a;
                font-weight: 300;
                font-family: "Basis Grotesque Arabic Pro";
            }
        }
        &__footer__text {
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 15px;
            color: #ffffff;
            font-weight: 500;
            font-family: "Basis Grotesque Arabic Pro";
        }
        &__footer__left__text {
            font-size: 12px;
            letter-spacing: 0px;
            line-height: 20px;
            color: #000000;
            font-weight: 300;
            font-family: "Basis Grotesque Arabic Pro";
        }
        &__footer__right__text {
            font-size: 12px;
            letter-spacing: 0px;
            line-height: 20px;
            text-decoration: underline;
            color: #000000;
            font-weight: 300;
            font-family: "Basis Grotesque Arabic Pro";
        }
    }
}

.thankYouMain {
    // background: url("../../assets/img/bgImage.png");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    min-height: 550px;
    // @media (max-width: 780px) {
    //     min-height: auto !important;
    //     background-size: 100% 100%;
    //     background-repeat: no-repeat;
    // }
    // &__thanksText {
    //     font-size: 40px;
    //     line-height: 53px;
    //     color: #fefefe;
    //     font-weight: 700;
    //     font-family: "Montserrat";
    //     text-align: center;
    // }
}
